
import { Prop, Vue, Component } from 'vue-property-decorator';
import { Select, Option, Checkbox } from 'element-ui';

export interface Item {
    value: number | string;
    name: string;
    disabled?: boolean;
}

@Component({
    components: {
        'el-select': Select,
        'el-option': Option,
        'el-checkbox': Checkbox,
    },
})
export default class CustomSelect extends Vue {
    @Prop({ required: false, type: [Number, String, Array, Boolean] })
    value!: any;

    @Prop({ required: true, default: () => [] })
    items!: Item[];

    @Prop({ type: String })
    label?: string;

    @Prop({ type: Boolean, default: false })
    disabled?: boolean;

    @Prop({ type: Boolean, default: false })
    multiple?: boolean;

    @Prop({ type: Boolean, default: false })
    filterable?: boolean;

    @Prop({ type: String })
    title?: string;

    @Prop({ type: String })
    placeholder!: string;

    @Prop({ type: Number, default: 0 })
    stickyHeader!: number;

    @Prop({ type: String, default: '' })
    popperClass!: string;

    get resolvedPopperClass(): string {
        const classList = {
            'custom-select': true,
            [this.popperClass]: !!this.popperClass,
        };

        return Object.keys(classList).filter(key => classList[key]).join(' ');
    }

    get modelValue() {
        return this.value;
    }

    set modelValue(value: any) {
        this.$emit('input', value);
    }

    extraMarginDown(stickyValue: number): number {
        return Object.keys(this.$slots).length * Number(stickyValue);
    }
}
